.react-horizontal-scrolling-menu--wrapper {
    height: 80vh;
    width: 100vw;
}

.react-horizontal-scrolling-menu--wrapper .react-horizontal-scrolling-menu--inner-wrapper {
    height: 100%;
    width: 100vw;
}

.react-horizontal-scrolling-menu--wrapper .react-horizontal-scrolling-menu--inner-wrapper .react-horizontal-scrolling-menu--scroll-container {
    height: 100%;
    width: 100vw;
}

.react-horizontal-scrolling-menu--wrapper .react-horizontal-scrolling-menu--inner-wrapper .react-horizontal-scrolling-menu--scroll-container .react-horizontal-scrolling-menu--item {
    height: 100%;
    width: 100vw;
}

.react-horizontal-scrolling-menu--item {
    position: relative;
}

.react-horizontal-scrolling-menu--item:nth-last-of-type(1) {
    /* background: #1D1C1C; */
    z-index: 60;
}

.train {
    position: absolute;
    animation-name: drive;
    animation-duration: 3s;
    animation-fill-mode: both;
    animation-iteration-count: inifinite;
}

.podcast .react-horizontal-scrolling-menu--wrapper {
    height: 100%;
    width: 100%;
}

.podcast .react-horizontal-scrolling-menu--wrapper .react-horizontal-scrolling-menu--inner-wrapper {
    height: 100%;
    width: 100%;
}

.podcast .react-horizontal-scrolling-menu--wrapper .react-horizontal-scrolling-menu--inner-wrapper .react-horizontal-scrolling-menu--scroll-container {
    height: 100%;
    width: 100%;
}

.podcast .react-horizontal-scrolling-menu--wrapper .react-horizontal-scrolling-menu--inner-wrapper .react-horizontal-scrolling-menu--scroll-container .react-horizontal-scrolling-menu--item {
    height: 100%;
    width: 100%;
    margin-right: 15px;
}

.podcast .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}
  
.podcast .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.podcast .react-horizontal-scrolling-menu--item:first-child {
    margin-right: 0px;
}

.podcast .react-horizontal-scrolling-menu--item:last-child {
    margin-right: 0px;
}

@keyframes drive {
    from{ transform: translateX(1000px)}
    to{ transform: translateX(100px)}
}

@media (min-height: 700px) {
    .contact-form {
        height: 496px;
    }
}

@media (min-height: 1000px) {
    .contact-form {
        height: 617px;
    }
}

