
.slideShow {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 120px;

}

.slideTrack {
    position: absolute;
    display: flex;
    justify-content: space-around;
    left: 0px;
    top: 0px;
    /* height: 120px; */
    width: 3148px; /* TODO */
    animation: slideshow 20s linear infinite;
    transform: translate3d(0, 0, 0);
}

.slideTrackReplicate {
    opacity: 0%;
    position: absolute;
    display: flex;
    justify-content: space-around;
    left: 0px;
    top: 0px;
    /* height: 120px; */
    width: 3148px; /* TODO */
    animation: slideshow 20s linear 10s infinite;
    transform: translate3d(0, 0, 0);
}

.slide {
    /* width: fit-content; */
    margin-top: 0px;
    display: inline-block;
    height: 120px;
}

@media only screen and (min-width: 1024px) {
  .slide {
    /* width: fit-content; */
    margin-top: 80px;
  }

  .slideShow {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 280px;

  }
}



@keyframes slideshow {
    0% {
        opacity: 1;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
    }
}
