#blog-content p {
    font-size: 14px;
    font-weight: 400;
    color: #E9E9E9;
    line-height: 21px;
    margin-bottom: 21px;
}
#blog-content h1 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
    color: white;
}
#blog-content h2 {
    font-size: 14px;
    font-weight: 700;
    color: white;
    margin-top: 30px;
}
#blog-content h3 {
    font-size: 10px;
    font-weight: 500;
}

@media screen and (min-width: 1536px){
    .reactMarkdown h1 {
        line-height: 5rem;
    }
}